import React, { forwardRef } from 'react';
import NextLink from 'next/link';
import { Link as ChLink } from '@chakra-ui/react';

const Link = forwardRef(
  (
    { href, prefetch, replace, scroll, shallow, locale, isExternal, ...props },
    ref
  ) => {
    if (isExternal)
      return <ChLink href={href} ref={ref} isExternal {...props} />;
    return (
      <ChLink
        as={NextLink}
        href={href}
        prefetch={prefetch}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        locale={locale}
        ref={ref}
        {...props}
      />
    );
  }
);

Link.displayName = 'Link';

Link.defaultProps = {
  shallow: true
};

export default Link;
