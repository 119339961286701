import React from 'react';
// import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { Box, Circle } from '@chakra-ui/react';
import { responsive } from '../contexts/responsive';
// import Button from './Button';

// const Corner = ({ transform, ...props }) => (
//   <Box width="100%" {...props}>
//     <Box
//       width="100%"
//       pt="100%"
//       borderLeft="1px solid currentColor"
//       borderBottom="1px solid currentColor"
//       transform={transform}
//     />
//   </Box>
// )

const Chevron = ({ children, slideCount, currentSlide, ...props }) => (
  <Circle bg="transparent" border="1px solid black" {...props}>
    <Box
      pos="absolute"
      left="50%"
      top="50%"
      transformOrigin="center"
      transform="rotate(180deg) translate(50%, 50%)"
      width="100%"
    >
      <svg viewBox="0 0 50 50" alt="上一頁">
        <use xlinkHref="/sprites/home.svg#mediumArrow" />
      </svg>
    </Box>
  </Circle>
);

const ChevronRight = ({ children, slideCount, currentSlide, svgProps, ...props }) => (
  <Circle bg="transparent" border="1px solid black" {...props}>
    <Box
      pos="absolute"
      left="50%"
      top="50%"
      transform="translate(-50%, -50%)"
      width="100%"
    >
      <svg viewBox="0 0 50 50" alt="下一頁">
        <use xlinkHref="/sprites/home.svg#mediumArrow" />
      </svg>
    </Box>
  </Circle>
);

// const ChevronTop = ({ children, ...props }) => (
//   <Circle bg="black" width={responsive('2.5em', '3.5em')} color="white" position="absolute" top="0" left="0" transform="translate(0%, -120%)" {...props}>
//     <Box transform="rotate(-90deg)">
//       <IoIosPlay size="1.5em" />
//     </Box>
//   </Circle>
// )

// const ChevronBottom = ({ children, ...props }) => (
//   <Circle bg="black" width={responsive('2.5em', '3.5em')} color="white" position="absolute" bottom="0" left="0" transform="translate(0%, 120%)" {...props}>
//     <Box transform="rotate(90deg)">
//       <IoIosPlay size="1.5em" />
//     </Box>
//   </Circle>
// )

Chevron.Left = Chevron;
Chevron.Right = ChevronRight;
// Chevron.Top = ChevronTop
// Chevron.Bottom = ChevronBottom

export default Chevron;
