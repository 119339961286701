import React from 'react';
import SVG from './SVG';

const Logo = ({ variant, ...props }) => {
  const logos = {
    full: {
      viewbox: '0 0 464 100',
      use: '/sprites/home.svg#logoFull'
    },
    normal: {
      viewbox: '0 0 400 40',
      use: '/sprites/home.svg#logo'
    },
    short: {
      viewbox: '0 0 240 60',
      use: '/sprites/home.svg#logoShort'
    }
  };

  return (
    <SVG viewBox={logos[variant].viewbox} {...props}>
      <title>超開放實驗室 OOOPen Lab</title>
      <use xlinkHref={logos[variant].use} />
    </SVG>
  );
};

export default Logo;
