import React, { createElement } from 'react';
import { Button as ChButton, forwardRef } from '@chakra-ui/react';
import { usePlausible } from 'next-plausible';
import capitalize from 'lodash/capitalize';

import Link from './Link';

const Button = forwardRef(
  (
    {
      href,
      isExternal,
      trackerName,
      trackerType = 'click_button',
      trackPixel,
      onClick,
      as = ChButton,
      icon,
      leftIcon,
      rightIcon,
      ...props
    },
    ref
  ) => {
    const plausible = usePlausible();
    const handleTrackEvent = (e) => {
      if (onClick) {
        onClick(e);
      }
      if (trackerName) {
        if (typeof window.gtag === 'function') {
          window.gtag('event', trackerType, {
            button_name: trackerName
          });
        }

        plausible('clickButton', {
          props: {
            buttonName: trackerName
          }
        });
        if (trackPixel && typeof window.fbq === 'function') {
          window.fbq('track', `OOOPEN Lab - Click${capitalize(trackerName)}`);
        }
      }
    };
    const sizedIcon = icon ? React.cloneElement(icon, { size: '1.5em' }) : icon;
    const sizedLeftIcon = leftIcon
      ? React.cloneElement(leftIcon, { size: '1.5em' })
      : leftIcon;
    const sizedRightIcon = rightIcon
      ? React.cloneElement(rightIcon, { size: '1.5em' })
      : rightIcon;
    if (href && !props.isDisabled) {
      return createElement(as, {
        ref,
        href,
        as: Link,
        isExternal,
        onClick: handleTrackEvent,
        icon: sizedIcon,
        leftIcon: sizedLeftIcon,
        rightIcon: sizedRightIcon,
        ...props
      });
    }

    return createElement(as, {
      ref,
      onClick: handleTrackEvent,
      leftIcon: sizedLeftIcon,
      rightIcon: sizedRightIcon,
      icon: sizedIcon,
      ...props
    });
  }
);

export default Button;
