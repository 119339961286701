import React, { useRef } from 'react';
import { Box, Flex, HStack } from '@chakra-ui/react';

// import Link from 'components/Link';
import HeaderMenu from './HeaderMenu';
import Logo from 'components/Logo';
import Link from 'components/Link';
import Media from 'components/Media';
import Button from 'components/Button';

const buttons = [
  { name: '模組介紹', href: '/#game', trackerName: 'nav_module' },
  { name: '品牌範例', href: '/#play', trackerName: 'nav_example' },
  { name: '方案說明', href: '/#plans', trackerName: 'nav_plan' },
  { name: '常見問題', href: '/faq', trackerName: 'nav_faq' },
  { name: '文章專區', href: '/blog', trackerName: 'nav_blog' },
];

const OpenLabHeader = () => {
  // console.log(fpApiRef)
  return (
    <Flex
      as="header"
      position="fixed"
      left="0"
      right="0"
      top="0"
      bg="white"
      justifyContent="space-between"
      px={['1.125em', '1.5em']}
      py={{
        base: 2,
        md: 4
      }}
      alignItems="center"
      zIndex="docked"
      borderBottom="1px solid"
    >
      <Box flex="0 0 160px">
        <Link href="/" passHref>
          <Logo variant="full" w="full" verticalAlign="middle" />
        </Link>
      </Box>
      <HStack as={Media} greaterThanOrEqual="md" spacing={2}>
        {buttons.map(({ name, href, trackerName }) => (
          <Box fontWeight={500} px="1em" key={name}>
            <Link href={href}>
              <Button
                trackerType="nav_click"
                trackerName={trackerName}
                variant="pure"
              >
                {name}
              </Button>
            </Link>
          </Box>
        ))}
        <Link href="/login">
          <Button
            py="0.375rem"
            height="auto"
            width="auto"
            px="1.25rem"
            bg="transparent"
            border="1px solid"
            color="black"
            trackerName="nav_login"
            trackerType="nav_click"
            _hover={{}}
            _active={{}}
          >
            登入/註冊
          </Button>
        </Link>
      </HStack>
      <Media lessThan={'md'}>
        <Box as="nav">
          <HeaderMenu buttons={buttons} />
        </Box>
      </Media>
    </Flex>
  );
};

export default OpenLabHeader;
