import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  HStack,
  IconButton,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { AiOutlineMenu, AiOutlineMail } from 'react-icons/ai';
import React from 'react';
import Logo from 'components/Logo';
import Link from 'components/Link';

const HeaderMenu = ({ buttons }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <IconButton
        variant="ghost"
        icon={<AiOutlineMenu size="2em" />}
        size="md"
        aria-label="開啟選單"
        onClick={onOpen}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody pt="20">
            <Stack spacing={4}>
              {buttons.map(({ name, href }) => (
                <Box
                  fontWeight={500}
                  onClick={onClose}
                  fontSize="1.375em"
                  key={name}
                >
                  <Link href={href}>{name}</Link>
                </Box>
              ))}
              <Link href="/login">
                <Button
                  py="0.25rem"
                  height="auto"
                  width="auto"
                  px="1.25rem"
                  fontSize={'1.275em'}
                  bg="transparent"
                  border="1px solid"
                  color="black"
                  _hover={{}}
                  _active={{}}
                >登入/註冊
                </Button>
              </Link>
            </Stack>
          </DrawerBody>
          <DrawerFooter bg="black" color="white" justifyContent="space-between">
            <HStack>
              <Link href="/">
                <Logo variant="full" width="8em" />
              </Link>
            </HStack>
            <IconButton
              variant="outline"
              colorScheme="whiteAlpha"
              size="sm"
              icon={<AiOutlineMail size="1.25em" />}
              as={Link}
              href="mailto:ooopenlab@relab.cc"
              color="white"
              aria-label="寄信問問題"
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default HeaderMenu;
